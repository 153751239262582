import { createSlice, createSelector } from '@reduxjs/toolkit';
import SurveyApi from 'api/SurveyApi';
import SurveyService from 'repositories/Survey';

const surveySlice = createSlice({
  name: 'survey',
  initialState: {
    isLoading: false,
    creating: false,
    data: [],
    singleSurvey: {},
    singleSurveyReport: {},
    singleSurveyReportError: null,
    toggleLoading: false,
  },
  reducers: {
    getAll: (state, action) => {
      state.isLoading = true;
    },
    getAllSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.data = action.payload.data;
    },
    getAllFailed: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    getOne: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    getOneSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.singleSurvey = action.payload.data;
    },
    getOneFailed: (state, action) => {
      state.loading = false;
      state.error = null;
    },

    addQuestionSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.singleSurvey.questions.unshift(action.payload);
    },

    toggleActive: (state, action) => {
      state.toggleLoading = true;
      state.error = null;
    },
    toggleActiveSuccess: (state, action) => {
      state.toggleLoading = false;
      state.error = null;
      const index = state.data.findIndex((item) => item.id === action.payload.id);
      state.data[index].status = action.payload.status;
    },
    toggleActiveFailed: (state, action) => {
      state.toggleLoading = false;
      state.error = null;
    },

    getSingleSurveyReport: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    getSingleSurveyReportSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.singleSurveyReport = action.payload;
    },
    getSingleSurveyReportFailed: (state, action) => {
      state.loading = false;
      state.singleSurveyReportError = action.payload;
    },
  },
});

const { actions, reducer } = surveySlice;

const getSurveys = (state) => state.survey.data;
const getIsLoading = (state) => state.survey.loading;
const getIsCreating = (state) => state.survey.creating;
const getToggleLoading = (state) => state.survey.toggleLoading;
const getSingleSurvey = (state) => state.survey.singleSurvey;

const selectors = {
  data: createSelector(getSurveys, (data) => data),
  isLoading: createSelector(getIsLoading, (data) => data),
  isCreating: createSelector(getIsCreating, (data) => data),
  toggleLoading: createSelector(getToggleLoading, (data) => data),
  getSingleSurvey: createSelector(getSingleSurvey, (data) => data),
};

const getAll = (query) => async (dispatch) => {
  dispatch(actions.getAll());
  try {
    const response = await SurveyApi.getAll('survey', query);
    if (response.data.success || response.data.status === 200) {
      dispatch(actions.getAllSuccess(response.data));
      return {
        data: response.data,
        page: response.current_page - 1,
        totalCount: response.total,
      };
    } else {
      throw Error(response.errMgs ? response.errMgs : 'Get survey Failed');
    }
  } catch (error) {
    console.log(error);
    dispatch(actions.getAllFailed(error.message));
    return error;
  }
};

const toggleActive = (id, status) => async (dispatch) => {
  console.log({ id, status });
  // dispatch(actions.toggleActive());
  try {
    const response = await SurveyApi.toggleActive(id, status);
    if (response.success) {
      dispatch(actions.toggleActiveSuccess({ id, status }));
      return response;
    } else {
      throw Error(response.errMgs ? response.errMgs : `Failed to activate survey ${id}`);
    }
  } catch (error) {
    console.log(error);
    dispatch(actions.toggleActiveFailed());
    return error;
  }
};

const singleSurvey = (id) => async (dispatch) => {
  dispatch(actions.getOne());
  try {
    const response = await SurveyApi.getOne(id);
    if (response.data.success || response.data.status === 200) {
      dispatch(actions.getOneSuccess(response.data));
      return response.data;
    } else {
      throw Error(response.errMgs ? response.errMgs : 'Get Single Survey Failed');
    }
  } catch (error) {
    console.log(error);
    dispatch(actions.getOneFailed(error.message));
    return error;
  }
};

const singleSurveyReport = (id) => async (dispatch) => {
  dispatch(actions.getSingleSurveyReport());
  try {
    const response = await SurveyService.singleReport(id);
    if (response.success || response.status === 200) {
      dispatch(actions.getSingleSurveyReportSuccess(response.data));
      return response.data;
    } else {
      throw Error(response.errMgs ? response.errMgs : 'Get Single Survey Report Failed');
    }
  } catch (error) {
    console.log(error);
    dispatch(actions.getSingleSurveyReportFailed(error.message));
    return error;
  }
};

const addQuestion = (question) => async (dispatch) => {
  if (question) {
    dispatch(actions.addQuestionSuccess(question));
    return question;
  }
};

export default {
  actions,
  reducer,
  selectors,
  getAll,
  toggleActive,
  singleSurvey,
  singleSurveyReport,
  addQuestion,
};
