import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { QueryCache, ReactQueryCacheProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query-devtools';

import './index.css';
import 'mapbox-gl/dist/mapbox-gl.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import configureStore from './store/configureStore';

export const queryCache = new QueryCache();
const { store, persistor } = configureStore();

// Sentry.init({ dsn: 'https://e4b1bcb68cfe438e92e9d3a2fc59fb16@sentry.io/1864071' });

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ReactQueryCacheProvider queryCache={queryCache}>
        <App />
        <ReactQueryDevtools initialIsOpen />
      </ReactQueryCacheProvider>
    </PersistGate>
  </Provider>,

  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
