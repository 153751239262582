import AuthFetch from 'api/auth';
import SuperFetch from 'api/SuperFetch';

const Auth = {
  login: values => {
    return AuthFetch('hats/login', {
      method: 'POST',
      body: JSON.stringify(values),
    });
  },
  changePassword: values => {
    return SuperFetch('hats/change-pass', {
      method: 'POST',
      data: values,
    });
  },
};

export default Auth;
