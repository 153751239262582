import React from 'react';
import { Route, Switch } from 'react-router-dom';
import loadable from '@loadable/component';

import Loading from '../components/Loading';

const Overview = loadable(() => import('./Overview'), {
  fallback: Loading({
    pastDelay: true,
    error: false,
    timedOut: false,
  }),
});

const Survey = loadable(() => import('./Survey'), {
  fallback: Loading({
    pastDelay: true,
    error: false,
    timedOut: false,
  }),
});

const SingleSurvey = loadable(() => import('./SingleSurvey'), {
  fallback: Loading({
    pastDelay: true,
    error: false,
    timedOut: false,
  }),
});

const CreateSurvey = loadable(() => import('./CreateSurvey'), {
  fallback: Loading({
    pastDelay: true,
    error: false,
    timedOut: false,
  }),
});
const SurveyQuestionFormsCreateContainer = loadable(
  () => import('./SurveyForm/SurveyQuestionFormsCreateContainer'),
  {
    fallback: Loading({
      pastDelay: true,
      error: false,
      timedOut: false,
    }),
  },
);

const SurveyUserResponse = loadable(() => import('./SurveyUserResponse'), {
  fallback: Loading({
    pastDelay: true,
    error: false,
    timedOut: false,
  }),
});

const RespondantsList = loadable(() => import('./RespondantsList'), {
  fallback: Loading({
    pastDelay: true,
    error: false,
    timedOut: false,
  }),
});

const UpdateSurvey = loadable(() => import('./UpdateSurvey'), {
  fallback: Loading({
    pastDelay: true,
    error: false,
    timedOut: false,
  }),
});

const Location = loadable(() => import('./Location'), {
  fallback: Loading({
    pastDelay: true,
    error: false,
    timedOut: false,
  }),
});

const Routes = (props) => {
  return (
    <Switch>
      <Route path="/location/:id" component={Location} />
      <Route path="/survey/update/:id" component={UpdateSurvey} />
      <Route path="/survey/create/:id" component={SurveyQuestionFormsCreateContainer} />
      <Route path="/survey/create/" component={CreateSurvey} />
      <Route path="/survey/:surveyId/response/:responseId" component={SurveyUserResponse} />
      {/* <Route path="/survey/:surveyId/response/" component={RespondantsList} /> */}
      <Route path="/survey/:id" component={SingleSurvey} />
      <Route path="/survey" component={Survey} />
      <Route path="/" component={Overview} />
    </Switch>
  );
};

export default Routes;
