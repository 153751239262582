import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import Sidebar from '../components/Sidebar';
import TopBar from '../components/TopBar';
import Routes from './Routes';

const drawerWidth = 260;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    width: '100%',
    overflow: 'hidden',
    boxSizing: 'border-box',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
    },
  },
}));

export default function Main({ location }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const matches = useMediaQuery((theme) => theme.breakpoints.up('sm'));

  useEffect(() => {
    setOpen(matches);
  }, [matches]);

  function handleDrawerOpen() {
    setOpen(true);
  }

  function handleDrawerClose() {
    setOpen(false);
  }

  return (
    <div className={classes.root}>
      <TopBar handleDrawerOpen={handleDrawerOpen} open={open} drawerWidth={drawerWidth} />
      <Sidebar
        location={location}
        open={open}
        handleDrawerClose={handleDrawerClose}
        drawerWidth={drawerWidth}
      />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Routes />
      </main>
    </div>
  );
}
