import BaseApi from 'api/BaseApi';
import querparser from 'query-string';

export default class MerchantApi extends BaseApi {
  static getAll = (key, query = { limit: 10, page: 1, sort: 'id', order: 'desc' }) => {
    return this.Axios.get(
      `hats/survey?${querparser.stringify(query, {
        skipEmptyString: true,
        skipNull: true,
      })}`,
    );
  };

  static toggleActive = (id, status) => {
    return this.Axios.post(`hats/survey/${id}/status/?status=${status}`);
  };

  static getOne = (id) => {
    return this.Axios.get(`hats/survey/${id}`);
  };
}
