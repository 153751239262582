import Assessment from '@material-ui/icons/Assessment';
import ViewCarouselIcon from '@material-ui/icons/ViewCarousel';
import MapIcon from '@material-ui/icons/Map';
export default [
  {
    name: 'Overview',
    path: '/',
    Icon: ViewCarouselIcon,
  },
  {
    name: 'Survey',
    path: '/survey',
    Icon: Assessment,
  },

  // {
  //   name: 'Location',
  //   path: '/location',
  //   Icon: MapIcon,
  // },
];
