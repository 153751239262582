import SuperFetch from '../api/SuperFetch';
import querparser from 'query-string';

class Survey {
  create(data) {
    return SuperFetch('hats/survey/', {
      method: 'POST',
      'Content-Type': 'multipart/form-data',
      data,
    });
  }

  createQuestion(data) {
    return SuperFetch('hats/surveys/questions/', {
      method: 'POST',
      'Content-Type': 'multipart/form-data',
      data,
    });
  }

  updateSingleQuestion(questionId, data) {
    return SuperFetch(`hats/surveys/questions/${questionId}`, {
      method: 'POST',
      'Content-Type': 'multipart/form-data',
      data,
    });
  }

  updateSingleSurvey(surveyId, data) {
    return SuperFetch(`hats/survey/${surveyId}`, {
      method: 'POST',
      'Content-Type': 'multipart/form-data',
      data,
    });
  }

  getOne(id) {
    return SuperFetch(`hats/survey/${id}`);
  }

  getAll(_key, query = { limit: 10, page: 1 }) {
    return SuperFetch(
      `hats/survey?${querparser.stringify(query, {
        skipEmptyString: true,
        skipNull: true,
      })}`,
    );
  }

  updateStatus(id, status) {
    return SuperFetch(`hats/survey/${id}/status?status=${status}`, {
      method: 'POST',
      'Content-Type': 'multipart/form-data',
    });
  }

  createQuestionMapping(questionId, data) {
    return SuperFetch(`/hats/surveys/questions/${questionId}/question-mapping`, {
      method: 'POST',
      data,
    });
  }

  reportReview(reviewId, surveyId) {
    return SuperFetch(`hats/survey/review/${reviewId}?survey_id=${surveyId}`);
  }

  singleSurveyOverviewReport(surveyId, query) {
    let optionsId = null;
    console.log(query.optionsIds);
    if (query.optionsIds && query.optionsIds.length !== 0) {
      query.optionsIds.map((item, index) => {
        return (optionsId += `&option_ids[${index}]=${item.value}`);
      });
      return SuperFetch(
        `hats/survey/${surveyId}/single-report?age_from=${query.age_from}&age_to=${query.age_to}&${optionsId}&gender=${query.gender}`,
      );
    } else {
      return SuperFetch(
        `hats/survey/${surveyId}/single-report?age_from=${query.age_from}&age_to=${query.age_to}&gender=${query.gender}`,
      );
    }
  }

  singleUserReportReview(userId, surveyId) {
    return SuperFetch(`/hats/survey/review/${userId}?survey_id=${surveyId}`);
  }

  singleSurveyRespondants(surveyId, query = { limit: 10, page: 1 }) {
    return SuperFetch(
      `hats/survey/reports?limit=10&survey_id=${surveyId}&${querparser.stringify(query)}`,
    );
  }

  singleSurveyApproval(historyId, data, status) {
    return SuperFetch(`/hats/survey/approval/${historyId}?status=${status}`, {
      method: 'POST',
      data: data,
    });
  }
}

export default new Survey();
