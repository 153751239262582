import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Hidden from '@material-ui/core/Hidden';
import Typography from '@material-ui/core/Typography';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import { Redirect } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import { login, selectors } from '../modules/auth';
import loginImage from '../assets/login.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100vw',
    height: '100vh',
    padding: theme.spacing(8),
    margin: 0,
    background: '#4a0089',
    [theme.breakpoints.down('lg')]: {
      padding: theme.spacing(2),
    },
  },
  form: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    marginBottom: theme.spacing(6),
  },
  margin: {
    margin: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(1),
  },
  textField: {},
  heading: {
    color: 'white',
  },
  welcomeTxt: {
    color: '#6C6C6C',
    fontWeight: 'bold',
  },
  loginTxt: {
    color: '#707070',
    marginBottom: theme.spacing(6),
  },
  loginPaper: {
    height: '100%',
    padding: theme.spacing(8),
    borderRadius: theme.spacing(6),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
      borderRadius: theme.spacing(2),
      height: 'auto',
    },
  },
  loginGrid: {
    height: '100%',
  },
  loginImg: {
    width: '1060px',
    margin: `${theme.spacing(4)}px auto`,
    marginTop: theme.spacing(4),
    display: 'block',
    [theme.breakpoints.down('lg')]: {
      width: '1060px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      margin: `${theme.spacing(1)}px auto`,
      marginTop: theme.spacing(4),
    },
  },
  firstGrid: {
    [theme.breakpoints.down('sm')]: {
      order: 2,
      marginTop: theme.spacing(2),
    },
  },
  container: {
    height: '100%',
  },
}));

export default function Login(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { loginStatus, loading, error } = useSelector(selectors.getAuth);
  const { register, handleSubmit, errors } = useForm();
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const onSubmit = (data) => {
    dispatch(login(data));
  };

  // const handleSubmit = e => {
  //   e.preventDefault();
  //   dispatch(login(values.loginId, values.password));
  // };

  let { from } = props.location.state || { from: { pathname: '/' } };

  if (loginStatus) return <Redirect to={from} />;

  return (
    <div className={classes.root}>
      <Grid container direction="row" justify="center" className={classes.container} spacing={4}>
        <Grid item sm={12} md={7} lg={8} xl={9} className={classes.firstGrid}>
          <Hidden mdDown>
            <Typography variant="h4" gutterBottom className={classes.heading} align="center">
              Know Your Customer To Know Your Real Business
            </Typography>
          </Hidden>

          <img src={loginImage} alt="" className={classes.loginImg} />
        </Grid>
        <Grid item sm={12} md={5} lg={4} xl={3}>
          <Hidden smUp>
            <Typography variant="h4" gutterBottom className={classes.heading} align="center">
              Know Your Customer To Know Your Real Business
            </Typography>
          </Hidden>
          <Paper className={classes.loginPaper}>
            <Typography variant="h5" className={classes.welcomeTxt}>
              Welcome Back
            </Typography>
            <Typography variant="subtitle1" gutterBottom className={classes.loginTxt}>
              Log in to continue
            </Typography>
            <form className={classes.form} autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
              <TextField
                id="login-id"
                label="Happihub Login ID"
                className={clsx(classes.margin, classes.textField)}
                inputRef={register}
                error={errors.email}
                name="email"
              />
              <TextField
                id="password"
                className={clsx(classes.margin, classes.textField)}
                type={showPassword ? 'text' : 'password'}
                label="Password"
                inputRef={register}
                error={errors.password}
                name="password"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        edge="end"
                        aria-label="Toggle password visibility"
                        onClick={handleClickShowPassword}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <Typography variant="subtitle2" gutterBottom align="center" color="secondary">
                {error.toString()}
              </Typography>
              <Button
                disabled={loading}
                type="submit"
                variant="contained"
                color="primary"
                className={classes.button}
              >
                Sign In
              </Button>
            </form>
            <Typography variant="subtitle2" gutterBottom align="center">
              Forgot Password?
            </Typography>
            <Typography variant="subtitle2" gutterBottom align="center">
              Call: +8801631724289
            </Typography>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}
