import { createSlice, createSelector } from '@reduxjs/toolkit';
import { updateToken } from 'api/SuperFetch';
import Auth from '../repositories/Auth';
import { enqueueSnackbar } from './notifications';

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    loading: false,
    loginStatus: false,
    data: {
      id: null,
      token: null,
      email: null,
    },
    accounts: [],
    error: '',
    success: '',
  },
  reducers: {
    login: (state, action) => {
      state.loading = true;
      state.error = '';
      state.success = '';
    },
    loginSuccess: (state, action) => {
      state.loading = false;
      state.error = '';
      state.loginStatus = true;
      state.data = action.payload;
      state.success = 'Login Successful! Welcome home! :D';
    },
    loginFailed: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.success = '';
    },

    changePassword: (state, action) => {
      state.loading = true;
    },
    changePasswordSuccess: (state, action) => {
      state.loading = false;
      state.error = '';
    },
    changePasswordFailed: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.success = '';
    },

    addAcount: (state, action) => {
      state.loading = true;
      state.error = '';
      state.success = '';
    },
    addAcountSuccess: (state, action) => {
      state.loading = false;
      state.error = '';
      state.accounts = state.accounts.filter(acc => acc.id !== action.payload.data.id);
      state.accounts.push(action.payload.data);
      state.success = 'Account added succesfully!';
    },
    addAcountFailed: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.success = '';
    },

    swithAccount: (state, action) => {
      state.loading = false;
      state.error = '';
      state.success = 'Account switched!';
      state.accounts = state.accounts.filter(acc => acc.id !== action.payload.id);
      state.accounts.push(state.data);
      state.data = action.payload;
    },

    removeAccount: (state, action) => {
      state.accounts = state.accounts.filter(acc => acc.id !== action.payload);
      state.success = 'Account removed successfully!';
    },

    logout: (state, action) => {
      state.loginStatus = false;
      state.data = {};
      state.accounts = [];
      state.error = '';
      state.success = '';
    },
  },
});

// Extract the action creators object and the reducer
export const { actions, reducer } = authSlice;

export const selectors = {
  getAuth: createSelector(
    state => state.auth,
    data => data,
  ),
};

export const login = values => async (dispatch, getState) => {
  dispatch(actions.login());
  try {
    const response = await Auth.login(values);
    if (response.token) {
      updateToken(response);
      dispatch(
        actions.loginSuccess({
          email: values.email,
          ...response,
        }),
      );
    } else {
      throw Error(response.errMgs ? response.errMgs : 'Login Failed');
    }
  } catch (error) {
    console.log(error);
    dispatch(actions.loginFailed(error.message));
  }
};

export const changePassword = values => async (dispatch, getState) => {
  dispatch(actions.changePassword());
  try {
    const response = await Auth.changePassword(values);
    if (response.success) {
      dispatch(actions.changePasswordSuccess());
      dispatch(
        enqueueSnackbar({
          message: 'Password changed successfully.',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success',
          },
        }),
      );
    } else {
      throw Error(response.errMgs ? response.errMgs : 'Password Change Failed');
    }
  } catch (error) {
    console.log(error);
    dispatch(actions.changePasswordFailed(error.message));
  }
};

export const addAcount = values => async (dispatch, getState) => {
  dispatch(actions.addAcount());
  try {
    const response = await Auth.login(values);
    if (response.success) {
      dispatch(actions.addAcountSuccess(response));
      dispatch(
        enqueueSnackbar({
          message: 'Account added successfully.',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success',
          },
        }),
      );
    } else {
      throw Error(response.errMgs ? response.errMgs : 'Login Failed');
    }
  } catch (error) {
    console.log(error);
    dispatch(actions.addAcountFailed(error.message));
    dispatch(
      enqueueSnackbar({
        message: 'Account add failed.',
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'error',
        },
      }),
    );
  }
};

export const swithAccount = payload => dispatch => {
  localStorage.setItem('happihub_master_id', payload.id);
  localStorage.setItem('happihub_master_token', payload.token);
  dispatch(actions.swithAccount(payload));
};

// Export the reducer, either as a default or named export
export default reducer;
