import { baseUrl, mallBaseUrl } from './apiConfig';
import { getAuth, clearAuth } from './auth';
import axios from 'axios';
import BaseApi from './BaseApi';

/**
 * Maps queries to REST API
 *
 * @param {string} url Request url with query, e.g orders?page=1
 * @param {Object} optionProps fetch options, e.g. { method: 'POST', headers: { 'Authorization': 'token' } }
 * @returns {Promise} the Promise for json
 */
const { id, token } = getAuth();
const instance = axios.create({
  baseURL: baseUrl,
});

const mallInstance = axios.create({
  baseURL: mallBaseUrl,
});

instance.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

if (id && token) {
  instance.defaults.headers.common['Authorization'] = token;
  instance.defaults.headers.common['id'] = id;
  mallInstance.defaults.headers.common['Authorization'] = token;
  mallInstance.defaults.headers.common['id'] = id;
}

export function updateToken(response) {
  localStorage.setItem('happihub_master_id', response.id);
  localStorage.setItem('happihub_master_token', response.token);
  instance.defaults.headers.common['Authorization'] = response.token;
  instance.defaults.headers.common['id'] = response.id;
  mallInstance.defaults.headers.common['Authorization'] = response.token;
  mallInstance.defaults.headers.common['id'] = response.id;
  BaseApi.init(response.id, response.token);
}

export default async (url, optionsProps = {}, fileUpload) => {
  const options = {
    method: 'GET',
    mode: 'cors',
    url: url,
    ...optionsProps,
  };

  if (fileUpload) {
    delete options.headers['Content-Type'];
  }

  try {
    const response = await instance(options);
    // console.log(response);
    if (response.status === 401 || response.status === 403) {
      clearAuth();
      throw new Error(response.statusText);
    }
    // if (response.statusText !== 'OK') {
    // console.log(response);
    // throw new Error(response.statusText);
    // }
    // console.log(response);
    return {
      status: response.status,
      ...response.data,
    };
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.error(error.response.data);
      console.error(error.response.status);
      console.error(error.response.headers);
      // throw error.response;
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.error(error.request);
      // throw error.request;
    } else {
      // Something happened in setting up the request that triggered an Error
      console.error('Error', error.message);
    }
    console.error(error.config);
    throw error;
  }
};

export const SuperUploader = async (url, optionsProps = {}) => {
  const { id, token } = getAuth();

  const options = {
    method: 'POST',
    mode: 'cors',
    ...optionsProps,
    headers: new Headers({
      Accept: 'application/json',
      Authorization: token,
      id: id,
      'X-Requested-With': 'XMLHttpRequest',
      ...optionsProps.headers,
    }),
  };

  try {
    const response = await fetch(`${baseUrl}/${url}`, options);
    // console.log(response);
    if (response.status === 401) {
      clearAuth();
      throw new Error(response.statusText);
    }
    if (!response.ok) {
      console.log(response);
      throw new Error(response.statusText);
    }
    const json = await response.json();
    return {
      status: response.status,
      ...json,
    };
  } catch (error) {
    console.log('SuperFetch Error ->', error.message);
    return error;
  }
};

export const SuperMallFetch = async (url, optionsProps = {}, fileUpload) => {
  const options = {
    method: 'GET',
    mode: 'cors',
    url: url,
    ...optionsProps,
  };

  if (fileUpload) {
    delete options.headers['Content-Type'];
  }

  try {
    const response = await mallInstance(options);
    // console.log(response);
    if (response.status === 401 || response.status === 403) {
      clearAuth();
      throw new Error(response.statusText);
    }
    // if (response.statusText !== 'OK') {
    // console.log(response);
    // throw new Error(response.statusText);
    // }
    // console.log(response);
    return {
      status: response.status,
      ...response.data,
    };
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.error(error.response.data);
      console.error(error.response.status);
      console.error(error.response.headers);
      // throw error.response;
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.error(error.request);
      // throw error.request;
    } else {
      // Something happened in setting up the request that triggered an Error
      console.error('Error', error.message);
    }
    console.error(error.config);
    throw error;
  }
};
