import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import IconButton from '@material-ui/core/IconButton';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import CancelIcon from '@material-ui/icons/Cancel';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import 'react-awesome-lightbox/build/style.css';

import DateFnsUtils from '@date-io/date-fns';

import theme from './lib/theme';
import Login from './pages/Login';
import Home from './pages';
import PrivateRoute from './components/PrivateRoute';
import Notifier from './components/Notifier';
import { getAuth } from 'api/auth';
import BaseApi from 'api/BaseApi';

const App = () => {
  const notistackRef = React.createRef();
  const onClickDismiss = key => () => {
    notistackRef.current.closeSnackbar(key);
  };
  React.useEffect(() => {
    const { id, token } = getAuth();
    if (id && token) {
      BaseApi.init(id, token);
    }
  }, []);
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <CssBaseline />
      <MuiThemeProvider theme={theme}>
        <SnackbarProvider
          ref={notistackRef}
          action={key => (
            <IconButton onClick={onClickDismiss(key)} aria-label="close">
              <CancelIcon style={{ color: '#FFF' }} />
            </IconButton>
          )}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <Notifier />

          <Router>
            <Switch>
              <Route exacte path="/login" component={Login} />
              <PrivateRoute exacte path="/" component={Home} />
            </Switch>
          </Router>
        </SnackbarProvider>
      </MuiThemeProvider>
    </MuiPickersUtilsProvider>
  );
};

export default App;
